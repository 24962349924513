<script>
import ClientsProjects from "../components/models/ClientsProjects.vue";

export default {
  data() {
    return {};
  },
  components: {
    ClientsProjects,
  },
};
</script>
<template>
  <ClientsProjects />
</template>
