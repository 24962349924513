<template>
  <div>
    <div class="tasks-info">
      <div class="card mb-4">
        <div
          class="card-header p-3"
          style="
            display: flex;
            justify-content: space-between;
            align-items: center;
          "
        >
          <div class="line short"></div>
          <div class="line short"></div>
        </div>

        <!-- tasks -->
        <div class="card-body px-0 pt-0 pb-2">
          <div class="table-responsive p-0">
            <table class="table mb-0">
              <thead>
                <tr>
                  <th
                    class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                  >
                    <div class="text-placeholder">
                      <div class="line short"></div>
                    </div>
                  </th>
                  <th
                    class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                  >
                    <div class="text-placeholder">
                      <div class="line short"></div>
                    </div>
                  </th>
                  <th
                    class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                  >
                    <div class="text-placeholder">
                      <div class="line short"></div>
                    </div>
                  </th>
                  <th
                    class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                  >
                    <div class="text-placeholder">
                      <div class="line short"></div>
                    </div>
                  </th>
                  <th
                    class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                  >
                    <div class="text-placeholder">
                      <div class="line short"></div>
                    </div>
                  </th>
                  <th
                    class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                  >
                    <div class="text-placeholder">
                      <div class="line short"></div>
                    </div>
                  </th>
                  <th class="text-secondary opacity-7">
                    <div class="text-placeholder">
                      <div class="line short"></div>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <!-- v-for="item in managersData" :key="item.id" -->
                <tr v-for="task in 10" :key="task.id">
                  <td style="width: 25%">
                    <div style="display: flex; width: 100%; gap: 8px">
                      <div class="image-placeholder"></div>
                      <div
                        class="text-placeholder"
                        style="width: 50% !important"
                      >
                        <div class="text-placeholder">
                          <div class="line long"></div>
                          <div class="line medium"></div>
                          <div class="line short"></div>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td class="align-middle text-start text-sm">
                    <div class="text-placeholder">
                      <div class="line short"></div>
                    </div>
                  </td>
                  <td>
                    <div class="avatar-group mt-2 d-flex">
                      <div class="small-image-placeholder"></div>
                      <div class="small-image-placeholder"></div>
                    </div>
                  </td>
                  <td class="align-middle text-center text-sm">
                    <div class="text-placeholder">
                      <div class="line short"></div>
                    </div>
                  </td>
                  <td class="align-middle text-center text-sm">
                    <div class="text-placeholder">
                      <div class="line short"></div>
                    </div>
                  </td>
                  <td class="align-middle text-center">
                    <div class="text-placeholder">
                      <div class="line short"></div>
                    </div>
                  </td>
                  <td class="align-middle align-end">
                    <div class="text-placeholder">
                      <div class="line short"></div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "NewPlaceHolder",
  props: {},

  data() {
    return {};
  },

  components: {},
  methods: {},
};
</script>

<style scoped lang="scss">
.image-placeholder {
  width: 80px; // Adjust the width as needed
  height: 80px; // Adjust the height as needed
  background-color: #f0f0f0;
  animation: pulse 1.5s infinite;
}

.small-image-placeholder {
  width: 38px; // Adjust the width as needed
  height: 38px; // Adjust the height as needed
  background-color: #f0f0f0;
  animation: pulse 1.5s infinite;
  border-radius: 50%;
}

.text-placeholder {
  display: flex;
  flex-direction: column;
  gap: 10px; // Adjust the gap between lines

  .line {
    height: 20px; // Adjust the height of each line
    background-color: #f0f0f0;
    animation: pulse 1.5s infinite;
  }

  .line.short {
    width: 60%; // Shorter line for variety
  }

  .line.medium {
    width: 80%; // Medium length line
  }

  .line.long {
    width: 100%; // Full width line
  }
}

.loader {
  display: flex;
  align-items: center;
  gap: 20px; // Adjust the gap between image and text placeholders

  .image-placeholder {
    flex: 0 0 150px; // Fixed width for the image placeholder
    height: 150px; // Fixed height for the image placeholder
  }

  .text-placeholder {
    flex: 1;
  }
}
@keyframes pulse {
  0% {
    background-color: #f0f0f0;
  }
  50% {
    background-color: #e0e0e0;
  }
  100% {
    background-color: #f0f0f0;
  }
}
</style>
