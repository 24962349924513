<template>
  <div class="container-fluid">
    <div class="Project-info">
      <div class="project-detail">
        <div class="mt-4 row">
          <div class="col-12">
            <div class="mb-4 card">
              <div class="p-3 pb-0 card-header d-flex flex-between">
                <div class="align-items-center">
                  <h6 class="mb-1">Project information</h6>
                  <i
                    v-if="
                      this.$store.state.user.role == 'admin' ||
                      this.$store.state.user.role == 'manager'
                    "
                    class="fa fa-pencil-square pointer"
                    @click="this.openProjectForm(this.projectId)"
                    aria-hidden="true"
                  ></i>
                </div>
                <soft-button-vue
                  v-if="
                    this.$store.state.user.role == 'admin' ||
                    this.$store.state.user.role == 'manager'
                  "
                  @click="this.openDeleteAlert(this.projectId)"
                  color="danger"
                  >Delete Project</soft-button-vue
                >
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="mb-4 col-xl-4 col-md-5 mb-xl-0">
                    <div class="card card-blog card-plain">
                      <div class="position-relative">
                        <a class="shadow-xl d-block border-radius-xl">
                          <img
                            :src="projectData.image ? projectData.image : img2"
                            alt="img-blur-shadow"
                            class="shadow img-fluid border-radius-xl"
                          />
                        </a>
                      </div>
                      <div class="px-1 pb-0 card-body">
                        <a href="javascript:;">
                          <h5>{{ projectData.title }}</h5>
                        </a>
                        <p class="mb-4 text-sm">
                          {{
                            projectData.description
                              ? projectData.description
                              : "Not mentioned"
                          }}
                        </p>
                        <div
                          class="d-flex align-items-center justify-content-between"
                        ></div>
                      </div>
                      <div
                        v-if="
                          this.$store.state.user.role == 'admin' ||
                          this.$store.state.user.role == 'manager'
                        "
                        class="project-button"
                      >
                        <SoftButtonVue
                          style="padding: 6px 16px"
                          @click="updateProjectStatus('active')"
                          :color="
                            projectData.status == 'active' ? 'warning' : ''
                          "
                          size="sm"
                        >
                          Active
                        </SoftButtonVue>

                        <SoftButtonVue
                          style="padding: 6px 8px"
                          @click="updateProjectStatus('pending')"
                          :color="
                            projectData.status == 'pending' ? 'secondary' : ''
                          "
                          size="sm"
                        >
                          Pre Construction
                        </SoftButtonVue>
                        <SoftButtonVue
                          v-if="this.$store.state.user.role == 'admin'"
                          style="padding: 6px 16px"
                          @click="updateProjectStatus('completed')"
                          :color="
                            projectData.status == 'completed' ? 'primary' : ''
                          "
                          variant="
                            outline
                          "
                          size="sm"
                        >
                          Completed
                        </SoftButtonVue>
                      </div>
                    </div>
                  </div>

                  <div class="mb-4 ml-4 col-xl-3 col-md-5 mb-xl-0">
                    <div class="card card-blog card-plain">
                      <h5>Informations</h5>
                      <div class="px-2 pb-0 card-body">
                        <div
                          class="show dropdown-menu-end me-sm-n4"
                          aria-labelledby="dropdownMenuButton"
                        >
                          <div class="mb-2">
                            <a
                              class="dropdown-item border-radius-md"
                              href="javascript:;"
                            >
                              <div class="py-1 d-flex">
                                <div
                                  class="d-flex flex-column justify-content-center"
                                >
                                  <h6 class="mb-1 text-sm font-weight-normal">
                                    <span class="font-weight-bold"
                                      >Start date</span
                                    >
                                  </h6>
                                  <p class="mb-0 text-xs text-secondary">
                                    <i class="fa fa-clock me-1"></i>
                                    {{
                                      projectData.startDate
                                        ? projectData.startDate
                                        : "Not mentioned"
                                    }}
                                  </p>
                                </div>
                              </div>
                            </a>
                          </div>

                          <div class="mb-2">
                            <a
                              class="dropdown-item border-radius-md"
                              href="javascript:;"
                            >
                              <div class="py-1 d-flex">
                                <div
                                  class="d-flex flex-column justify-content-center"
                                >
                                  <h6 class="mb-1 text-sm font-weight-normal">
                                    <span class="font-weight-bold"
                                      >End date</span
                                    >
                                  </h6>
                                  <p class="mb-0 text-xs text-secondary">
                                    <i class="fa fa-clock me-1"></i>
                                    {{
                                      projectData.endDate
                                        ? projectData.endDate
                                        : "Not metioned"
                                    }}
                                  </p>
                                </div>
                              </div>
                            </a>
                          </div>

                          <div class="mb-2">
                            <a
                              class="dropdown-item border-radius-md"
                              href="javascript:;"
                            >
                              <div class="py-1 d-flex">
                                <div
                                  class="d-flex flex-column justify-content-center"
                                >
                                  <h6 class="mb-1 text-sm font-weight-normal">
                                    <span class="font-weight-bold"
                                      >Total Tasks</span
                                    >
                                  </h6>
                                  <p class="mb-0 text-xs text-secondary">
                                    <i class="fa fa-list me-1"></i>
                                    {{
                                      projectData.total_tasks
                                        ? projectData.total_tasks
                                        : "No"
                                    }}
                                    Tasks
                                  </p>
                                </div>
                              </div>
                            </a>
                          </div>
                          <div class="mb-2">
                            <a
                              class="dropdown-item border-radius-md"
                              href="javascript:;"
                            >
                              <div class="py-1 d-flex">
                                <div
                                  class="d-flex flex-column justify-content-center"
                                >
                                  <h6 class="mb-1 text-sm font-weight-normal">
                                    <span class="font-weight-bold"
                                      >Active Tasks</span
                                    >
                                  </h6>
                                  <p class="mb-0 text-xs text-secondary">
                                    <i class="fa fa-list me-1"></i>
                                    {{
                                      this.activeTasks ? this.activeTasks : 0
                                    }}
                                    Tasks
                                  </p>
                                </div>
                              </div>
                            </a>
                          </div>
                          <div class="mb-2">
                            <a
                              class="dropdown-item border-radius-md"
                              href="javascript:;"
                            >
                              <div class="py-1 d-flex">
                                <div
                                  class="d-flex flex-column justify-content-center"
                                >
                                  <h6 class="mb-1 text-sm font-weight-normal">
                                    <span class="font-weight-bold"
                                      >Address</span
                                    >
                                  </h6>
                                  <p
                                    style="white-space: wrap"
                                    class="mb-0 text-xs text-secondary"
                                  >
                                    <i class="fa fa-home me-1"></i>
                                    {{
                                      projectData.address
                                        ? projectData.address
                                        : "Not mentioned"
                                    }}
                                  </p>
                                </div>
                              </div>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="mb-4 ml-3 col-xl-4 col-md-6 mb-xl-0">
                    <div class="card card-blog card-plain full-width">
                      <h5>Managers</h5>
                      <div class="px-0 pb-0 card-body">
                        <div
                          class="show dropdown-menu-end me-sm-n4"
                          aria-labelledby="dropdownMenuButton"
                        >
                          <div v-if="projectData.managers.length > 0">
                            <div
                              v-for="manager in projectData.managers"
                              :key="manager.id"
                              class="mb-2"
                            >
                              <a
                                class="dropdown-item border-radius-md"
                                href="javascript:;"
                              >
                                <div class="py-1 d-flex">
                                  <div class="my-auto">
                                    <img
                                      :src="
                                        manager.avatar
                                          ? manager.avatar
                                          : require('@/assets/preview.jpeg')
                                      "
                                      class="avatar avatar-sm me-3"
                                      alt="user image"
                                    />
                                  </div>
                                  <div
                                    class="d-flex flex-column justify-content-center"
                                  >
                                    <h6 class="mb-1 text-sm font-weight-normal">
                                      <span class="font-weight-bold">{{
                                        manager.username
                                          ? manager.username
                                          : "Not mentioned"
                                      }}</span>
                                    </h6>
                                    <p class="mb-0 text-xs text-secondary">
                                      <i class="fa fa-envelope me-1"></i>
                                      {{
                                        manager.email
                                          ? manager.email
                                          : "Not mentioned"
                                      }}
                                    </p>
                                  </div>
                                </div>
                              </a>
                            </div>
                          </div>
                          <div v-else class="mb-2">
                            <a
                              class="dropdown-item border-radius-md"
                              href="javascript:;"
                            >
                              <div class="py-1 d-flex">
                                <div class="my-auto">
                                  <img
                                    src="@/assets/preview.jpeg"
                                    class="avatar avatar-sm me-3"
                                    alt="user image"
                                  />
                                </div>
                                <div
                                  class="d-flex flex-column justify-content-center"
                                >
                                  <h6 class="mb-1 text-sm font-weight-normal">
                                    <span class="font-weight-bold"
                                      >No Manager Added</span
                                    >
                                  </h6>
                                  <p class="mb-0 text-xs text-secondary">
                                    <i class="fa fa-envelope me-1"></i>
                                    manager@email.com
                                  </p>
                                </div>
                              </div>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr class="horizontal dark my-sm-0" />
                    <div class="card card-blog card-plain full-width">
                      <div class="px-0 pb-0 card-body relative full-width">
                        <div
                          class="show dropdown-menu-end me-sm-n4 full-width"
                          aria-labelledby="dropdownMenuButton"
                        >
                          <div
                            v-if="
                              this.$store.state.user.role == 'admin' ||
                              this.$store.state.user.role == 'manager'
                            "
                            class="d-flex align-items-center space-between full-width"
                          >
                            <h5 class="mb-3">Client</h5>
                            <div
                              @click="toggleAddClient"
                              class="d-flex pointer"
                            >
                              <i
                                class="fa fa-pencil mt-1 mr-1"
                                aria-hidden="true"
                              ></i>
                              <div>
                                <p>Edit</p>
                              </div>
                            </div>
                          </div>
                          <div
                            v-if="showAddClient"
                            class="col-12 justify-conten-center d-flex"
                            style="
                              display: flex;
                              justify-content: center;
                              position: absolute;
                              background-color: #fff;
                            "
                          >
                            <div>
                              <div class="">
                                <label for="inputField">Clients : </label>
                              </div>
                              <select
                                class="inputField"
                                v-model="projectData.client"
                                @change="editProject"
                              >
                                <option
                                  class="dropdownOptions"
                                  v-for="client in clientsList"
                                  :key="client.id"
                                  :value="client.id"
                                >
                                  {{ client.username }}
                                </option>
                              </select>
                            </div>
                          </div>
                          <div v-if="projectData.client">
                            <div class="mb-2">
                              <a
                                class="dropdown-item border-radius-md"
                                href="javascript:;"
                              >
                                <div class="py-1 d-flex">
                                  <div class="my-auto">
                                    <img
                                      :src="
                                        projectData.client.avatar
                                          ? projectData.client.avatar
                                          : require('@/assets/preview.jpeg')
                                      "
                                      class="avatar avatar-sm me-3"
                                      alt="user image"
                                    />
                                  </div>
                                  <div
                                    class="d-flex flex-column justify-content-center"
                                  >
                                    <h6 class="mb-1 text-sm font-weight-normal">
                                      <span class="font-weight-bold">{{
                                        projectData.client.username
                                      }}</span>
                                    </h6>
                                    <p class="mb-0 text-xs text-secondary">
                                      <i class="fa fa-envelope me-1"></i>
                                      {{ projectData.client.email }}
                                    </p>
                                  </div>
                                </div>
                              </a>
                            </div>
                          </div>
                          <div v-else class="mb-2">
                            <a
                              class="dropdown-item border-radius-md"
                              href="javascript:;"
                            >
                              <div class="py-1 d-flex">
                                <div class="my-auto">
                                  <img
                                    src="@/assets/preview.jpeg"
                                    class="avatar avatar-sm me-3"
                                    alt="user image"
                                  />
                                </div>
                                <div
                                  class="d-flex flex-column justify-content-center"
                                >
                                  <h6 class="mb-1 text-sm font-weight-normal">
                                    <span class="font-weight-bold"
                                      >No client Added</span
                                    >
                                  </h6>
                                  <p class="mb-0 text-xs text-secondary">
                                    <i class="fa fa-envelope me-1"></i>
                                    client@email.com
                                  </p>
                                </div>
                              </div>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr class="horizontal dark my-sm-0" />
                    <div class="card card-blog card-plain">
                      <div class="px-0 pb-0 card-body relative">
                        <div
                          class="show dropdown-menu-end me-sm-n4 full-width"
                          aria-labelledby="dropdownMenuButton"
                        >
                          <div
                            class="d-flex align-items-center space-between full-width"
                          >
                            <h5>Contractor</h5>
                            <div
                              @click="toggleAddContractor"
                              class="d-flex pointer mt-2"
                              v-if="
                                this.$store.state.user.role == 'admin' ||
                                this.$store.state.user.role == 'manager'
                              "
                            >
                              <i
                                class="fa fa-pencil mt-1 mr-1"
                                aria-hidden="true"
                              ></i>
                              <div>
                                <p class="text-s">Edit</p>
                              </div>
                            </div>
                          </div>
                          <div
                            v-if="showAddContractor"
                            class="col-12 justify-conten-center d-flex"
                            style="
                              display: flex;
                              justify-content: center;
                              position: absolute;
                              background-color: #fff;
                            "
                          >
                            <div>
                              <div class="">
                                <label for="inputField">Contractors : </label>
                              </div>
                              <select
                                class="inputField"
                                v-model="projectData.contractor"
                                @change="editProject"
                              >
                                <option
                                  class="dropdownOptions"
                                  v-for="contractor in contarctorList"
                                  :key="contractor.id"
                                  :value="contractor.id"
                                >
                                  {{ contractor.username }}
                                </option>
                              </select>
                            </div>
                          </div>
                          <div v-if="projectData.contractor">
                            <div class="mb-2">
                              <a
                                class="dropdown-item border-radius-md"
                                href="javascript:;"
                              >
                                <div class="py-1 d-flex">
                                  <div class="my-auto">
                                    <img
                                      :src="
                                        projectData.contractor.avatar
                                          ? projectData.contractor.avatar
                                          : require('@/assets/preview.jpeg')
                                      "
                                      class="avatar avatar-sm me-3"
                                      alt="user image"
                                    />
                                  </div>
                                  <div
                                    class="d-flex flex-column justify-content-center"
                                  >
                                    <h6 class="mb-1 text-sm font-weight-normal">
                                      <span class="font-weight-bold">{{
                                        projectData.contractor.username
                                          ? projectData.contractor.username
                                          : "Not mentioned"
                                      }}</span>
                                    </h6>
                                    <p class="mb-0 text-xs text-secondary">
                                      <i class="fa fa-envelope me-1"></i>
                                      {{
                                        projectData.contractor.email
                                          ? projectData.contractor.email
                                          : "Not mentioned"
                                      }}
                                    </p>
                                  </div>
                                </div>
                              </a>
                            </div>
                          </div>
                          <div v-else class="mb-2">
                            <a
                              class="dropdown-item border-radius-md"
                              href="javascript:;"
                            >
                              <div class="py-1 d-flex">
                                <div class="my-auto">
                                  <img
                                    src="@/assets/preview.jpeg"
                                    class="avatar avatar-sm me-3"
                                    alt="user image"
                                  />
                                </div>
                                <div
                                  class="d-flex flex-column justify-content-center"
                                >
                                  <h6 class="mb-1 text-sm font-weight-normal">
                                    <span class="font-weight-bold"
                                      >No Contractor Added</span
                                    >
                                  </h6>
                                  <p class="mb-0 text-xs text-secondary">
                                    <i class="fa fa-envelope me-1"></i>
                                    contractor@email.com
                                  </p>
                                </div>
                              </div>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="
        this.$store.state.user.role == 'admin' ||
        this.$store.state.user.role == 'manager'
      "
      class="Project-info"
    >
      <div class="project-detail">
        <div class="mt-4 row">
          <div class="col-12">
            <div class="mb-4 card">
              <div class="p-3 pb-0 card-header"></div>
              <div class="card-body">
                <div class="row">
                  <div class="col-12 col-xl-6 col-md-12 col-sm-12">
                    <h6 style="text-align: center" class="mb-1">
                      Uploaded Spreadsheet(s)
                    </h6>
                    <div class="file-upload">
                      <div
                        v-for="(sheet, index) in projectData.uploaded_files"
                        :key="index"
                        class="d-flex space-between align-center"
                        style="border: 1px dashed #777; padding: 2px 12px"
                      >
                        <p>{{ sheet }}</p>
                        <i
                          @click="openSheetDeleteAlert(sheet)"
                          class="fa fa-trash-alt"
                        ></i>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-xl-6 col-md-12 col-sm-12">
                    <h6 style="text-align: center" class="mb-1">
                      Upload Spreadsheet to add Tasks
                    </h6>
                    <div class="mb-3 file-upload">
                      <label for="docpicker" class="file-label">
                        <span>
                          Click here to upload file and create tasks (xls
                          file)</span
                        >
                        <input
                          class="cus-inputField"
                          type="file"
                          id="docpicker"
                          accept=".xls,"
                          multiple
                          @change="handleFileSelect"
                        />
                      </label>
                      <div v-if="selectedFileName" class="selected-file">
                        <p class="upload-instructions">
                          {{ selectedFileName }}
                        </p>
                        <span
                          v-if="selectedFileName"
                          class="remove-file"
                          @click="this.removeFile()"
                          >✖</span
                        >
                      </div>
                      <div v-else>
                        <p class="upload-instructions">
                          Click here to upload file
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Placeloader v-if="this.Loading"></Placeloader>

    <projectTasks
      v-if="!this.Loading"
      :projectID="this.$route.params.id"
      @trigger-parent-function="this.getProjectTasks()"
    ></projectTasks>

    <sweetAlert ref="sweetAlert" :alertData="alertData">
      <template v-slot:actions>
        <soft-button-vue
          color="danger"
          :loading="loading"
          @click="
            () => {
              this.$refs.sweetAlert.closeModal();
            }
          "
        >
          Cancel
        </soft-button-vue>
        <soft-button-vue @click="this.deleteTask()" :loading="loading">
          Delete Task
        </soft-button-vue>
      </template>
    </sweetAlert>

    <sweetAlert ref="deleteSweetAlert" :alertData="delteAlertData">
      <template v-slot:actions>
        <soft-button-vue
          color="danger"
          :loading="loading"
          @click="
            () => {
              this.$refs.deleteSweetAlert.closeModal();
            }
          "
        >
          Cancel
        </soft-button-vue>
        <soft-button-vue @click="this.deleteProject()" :loading="loading">
          Delete Project
        </soft-button-vue>
      </template>
    </sweetAlert>

    <sweetAlert ref="deleteSheetSweetAlert" :alertData="delteSheetAlertData">
      <template v-slot:actions>
        <soft-button-vue
          color="danger"
          :loading="loading"
          @click="
            () => {
              this.$refs.deleteSheetSweetAlert.closeModal();
            }
          "
        >
          Cancel
        </soft-button-vue>
        <soft-button-vue @click="this.deleteSheetTasks()" :loading="loading">
          Delete Sheet
        </soft-button-vue>
      </template>
    </sweetAlert>

    <custom-modal ref="customModal" :title="modalTitle">
      <form id="project-form" @submit.prevent="addTaskHandler">
        <div class="flex-between">
          <div style="width: 100%">
            <label for="inputField">Title: *</label>
            <input
              class="inputField"
              type="text"
              required
              placeholder="Task title"
              v-model="taskData.title"
              size="md"
            />
          </div>
          <!-- <div style="width: 45%">
            <label for="inputField">Set status</label>
            <select
              style="padding: 6px 8px"
              required
              class="inputField"
              v-model="taskData.status"
            >
              <option
                class="dropdownOptions"
                v-for="task in Taskstatus"
                :key="task.value"
                :value="task.value"
              >
                {{ task.name }}
              </option>
            </select>
          </div> -->
        </div>
        <div>
          <label for="inputField">Description: *</label>
          <textarea
            rows="4"
            class="inputField"
            type="text"
            placeholder="Task description"
            v-model="taskData.description"
            size="md"
          />
        </div>

        <div style="display: flex; justify-content: space-between">
          <div style="width: 45%">
            <label for="inputField">Start Date</label>
            <input
              class="inputField"
              type="date"
              placeholder="Start date"
              v-model="taskData.startDate"
              size="md"
            />
          </div>

          <div style="width: 45%">
            <label for="inputField">End Date</label>
            <input
              class="inputField"
              type="date"
              placeholder="End date"
              v-model="taskData.endDate"
              size="md"
            />
          </div>
        </div>
        <div class="flex-between">
          <div style="width: 100%">
            <label for="inputField">Workers : *</label>
            <select
              required
              class="inputField"
              v-model="taskData.workers"
              multiple="true"
            >
              <option
                class="dropdownOptions"
                v-for="(worker, index) in workersData"
                :key="worker.id"
                :value="worker.id"
              >
                <p>{{ index + 1 }}).</p>
                {{ worker.username }}
              </option>
            </select>
          </div>
        </div>
        <div class="flex-between">
          <span>Press ctrl to selecte multiple</span>
        </div>
      </form>
      <template v-slot:actions>
        <SoftButtonVue
          color="success"
          form="project-form"
          type="submit"
          :loading="loading"
        >
          Add Task
        </SoftButtonVue>
      </template>
    </custom-modal>

    <custom-modal ref="editCustomModal" :title="editmodalTitle">
      <form id="project-form" @submit.prevent="editProject">
        <div class="row">
          <div class="col-6">
            <div>
              <label for="inputField">Title: *</label>
              <input
                class="inputField"
                type="text"
                required
                placeholder="Project title"
                v-model="projectData.title"
                size="md"
              />
            </div>
            <div>
              <label for="inputField">Start Date</label>
              <input
                class="inputField"
                type="date"
                placeholder="Start date"
                v-model="projectData.startDate"
                size="md"
              />
            </div>

            <div>
              <label for="inputField">End Date</label>
              <input
                class="inputField"
                type="date"
                placeholder="End date"
                v-model="projectData.endDate"
                size="md"
              />
            </div>
          </div>
          <div class="col-6 justify-conten-center">
            <img
              class="mb-2 ml-5"
              style="
                width: 140px;
                height: 140px;
                border-radius: 50%;
                margin-top: 10px;
              "
              :src="preview ? preview : '/preview.jpeg'"
              alt="asdas"
            />
            <input
              class="inputField"
              type="file"
              accept="image/*"
              @change="handleFileChange"
            />
          </div>
        </div>
        <div>
          <label for="inputField">Description: *</label>
          <input
            class="inputField"
            type="text"
            placeholder="Project description"
            v-model="projectData.description"
            size="md"
          />
        </div>

        <div style="display: flex; justify-content: space-between"></div>
        <div class="align-items-center">
          <input
            class="custom-checkbox"
            id="inputFieldcheck"
            type="checkbox"
            placeholder="Active"
            v-model="projectData.is_active"
          />
          <label class="mt-2" for="inputFieldcheck">Set Project Active</label>
        </div>
        <div>
          <div class="flex-between">
            <label for="inputField">Managers : </label>
            <i
              style="color: #249c56; font-size: 14px"
              @click="getManagershandler()"
              class="fa fa-plus"
              aria-hidden="true"
              >Add More Manager(s)</i
            >
          </div>
          <select class="inputField" v-model="selectedManagers" multiple="true">
            <option
              class="dropdownOptions"
              v-for="manager in projectData.managers"
              :key="manager.id"
              :value="manager.id"
            >
              {{ manager.username }}
            </option>
          </select>
        </div>
      </form>
      <template v-slot:actions>
        <SoftButtonVue form="project-form" type="submit" :loading="loading">
          Save Project
        </SoftButtonVue>
      </template>
    </custom-modal>

    <updateProject
      :isOpen="isProjectFormOpen"
      :closeModal="closeProjectForm"
      :projectId="editProjectId"
    />
    <update-task-vue
      v-if="isTaskFormOpen"
      :projectID="currentProjectId"
      :isOpen="isTaskFormOpen"
      :closeModal="closeTaskForm"
      :taskId="editTaskId"
    />
  </div>
</template>
<script>
import img1 from "@/assets/img/home-decor-1.jpg";
import SoftButtonVue from "../components/SoftButton.vue";
import img2 from "@/assets//img/home-decor-1.jpg";
import useApi from "../supportElements/useAPI";
import sweetAlert from "./components/customAlert.vue";
import CustomModal from "./components/CustomModal.vue";
import { convertToFormData } from "@/supportElements/common.js";
import updateTaskVue from "./SupportComponents/updateTask.vue";
import updateProject from "./SupportComponents/updateProject.vue";
import ProjectTasks from "./ProjectComponents/ProjectTasks.vue";
import spaceImg from "@/assets/img/team-4.jpg";

import Placeloader from "./SupportComponents/Placeloader.vue";

const api = useApi();
export default {
  name: "ProjectsDetail",
  data() {
    return {
      Loading: false,
      clientsList: [],
      contarctorList: [],
      showAddClient: false,
      showAddContractor: false,
      deleteTaskId: 0,
      activeTasks: 0,
      selectedManagers: [],
      preview: null,
      isProjectFormOpen: false,
      isTaskFormOpen: false,
      editTaskId: "",
      currentProjectId: "",
      editProjectId: null,
      loading: false,
      assignToMore: false,
      Taskstatus: [
        { value: "active", name: "Active" },
        { value: "pending", name: "Pre Construction" },
        { value: "completed", name: "Completed" },
        { value: "cancelled", name: "cancelled" },
      ],
      editmodalTitle: "Edit Project",
      modalTitle: "Add Task",
      alertData: {
        icon: "fa fa-bell",
        alertTitle: "Delete Task ?",
        alertDescription:
          "After deleting this task you will not be able to recover it",
      },
      delteAlertData: {
        icon: "fa fa-warning",
        alertTitle: "Delete Project Permanently?",
        alertDescription:
          "After deleting this Project you will not be able to recover it",
      },
      delteSheetAlertData: {
        icon: "fa fa-warning",
        alertTitle: "Delete Tasks' Sheet Permanently?",
        alertDescription:
          "After deleting this Sheet all tasks created by this sheet will be deleted permanently",
      },
      workersData: [{ id: 0, username: "", email: "", phoneNumber: "" }],

      projectId: this.$route.params.id,
      projectData: {
        id: 0,
        title: "",
        created: "",
        startDate: "",
        color: "",
        endDate: "",
        description: "",
        image: "",
        is_active: null,
        status: "",
        address: "",
        total_tasks: 0,
        client: 0,
        contractor: 0,
        clientInfo: {
          id: 0,
          username: "",
          email: "",
          password: "",
          avatar: File | String | null,
        },
        uploaded_files: [],
        contractorInfo: {
          id: 0,
          username: "",
          email: "",
          password: "",
          avatar: File | String | null,
        },
        managers: [
          {
            id: 0,
            username: "",
            avatar: null,
            email: "",
            role: "",
          },
        ],
      },

      taskData: {
        title: "",
        description: "",
        startDate: "",
        endDate: "",
        status: "",
        project: this.$route.params.id,
        workers: [],
      },

      projectTasks: [
        {
          id: 0,
          title: "",
          description: "",
          startDate: "",
          endDate: "",
          project: 0,
          status: "",
          worker: [{ id: 0, username: "", email: "", role: "", avatar: "" }],
        },
      ],
      img1,
      img2,
      spaceImg,
    };
  },

  components: {
    updateProject,
    CustomModal,
    updateTaskVue,
    sweetAlert,
    Placeloader,
    SoftButtonVue,
    ProjectTasks,
  },

  methods: {
    async deleteSheetTasks() {
      try {
        this.loading = true;
        const resp = api.patch(
          `/api/project/${this.projectId}/delete-doc-tasks/`,
          {
            document_name: this.sheetNameDeleteTobe,
          }
        );
        console.log(resp);
        this.getProject();
        this.$refs.deleteSheetSweetAlert.closeModal();
        this.$notify({
          type: "error",
          title: "Tasks Deleted",
          text: "All Tasks will be deleted that are created by Sheet!",
        });
        this.loading = false;
      } catch (err) {
        console.log(err);
      } finally {
        this.getProject();
      }
    },
    toggleAddContractor() {
      this.showAddContractor = !this.showAddContractor;
    },

    toggleAddClient() {
      this.showAddClient = !this.showAddClient;
    },

    openDeleteAlert(id) {
      this.$refs.deleteSweetAlert.openModal();
      this.projectIdDeleteTobe = id;
    },

    openSheetDeleteAlert(sheetName) {
      this.$refs.deleteSheetSweetAlert.openModal();
      this.sheetNameDeleteTobe = sheetName;
    },

    async deleteProject() {
      try {
        this.loading = true;
        const response = api.delete(
          `/api/project/${this.projectIdDeleteTobe}/`
        );
        this.$refs.deleteSheetSweetAlert.closeModal();
        console.log("deleted", response);
        this.$notify({
          type: "error",
          title: "Project deleted",
          text: "Selected project deleted successfuly!",
        });
        this.$router.push("/projects");
      } catch (err) {
        console.log(err);
        console.log("not deleted");
      } finally {
        this.loading = false;
      }
    },

    getActiveTasks() {
      this.activeTasks = this.projectTasks.filter(
        (tasks) => tasks.status == "active"
      );
      this.activeTasks = this.activeTasks.length;
    },

    closeTheModals() {
      this.taskData.title = "";
      this.taskData.description = "";
      this.taskData.startDate = "";
      this.taskData.endDate = "";
      this.taskData.workers = [];
      this.taskData.status = "";
    },

    async getProject() {
      try {
        this.Loading = true;
        this.loading = true;
        const resp = await api.get(`/api/project/${this.projectId}/`);
        this.projectData = resp.data;
        this.preview = resp.data.image;

        this.getProjectTasks();
        this.Loading = false;
        this.loading = true;
      } catch (err) {
        console.log(err);
      }
    },

    openAlert(id) {
      this.$refs.sweetAlert.openModal();
      this.deleteTaskId = id;
    },

    async getWorkershandler() {
      try {
        this.loading = true;
        const response = await api.get("/api/users/", {});

        this.workersData = response.data.filter(
          (worker) => worker.role == "worker"
        );

        this.clientsList = response.data.filter(
          (client) => client.role == "client"
        );

        this.contarctorList = response.data.filter(
          (contractor) => contractor.role == "contractor"
        );

        console.log("data", this.workersData);
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },

    async addTaskHandler() {
      try {
        this.loading = true;
        let formData = convertToFormData(this.taskData, []);
        const resp = await api.post("/api/task/", formData);
        this.closeTheModals();
        this.$refs.customModal.closeModal();
        this.getProject();
        this.getProjectTasks();
        this.$notify({
          type: "success",
          title: "Task Added",
          text: "Task added succesfuly",
        });
        console.log("task data", resp);
      } catch (err) {
        this.$notify({
          type: "error",
          title: "Warning",
          text: "Something went wrong",
        });
        console.log(err);
      } finally {
        this.loading = false;
      }
    },

    async getProjectTasks() {
      try {
        this.Loading = true;
        this.loading = true;
        const resp = await api.get(
          `api/task/${this.$route.params.id}/project/`
        );
        this.projectTasks = resp.data;
        console.log(resp.data);
        this.getActiveTasks();
        this.Loading = false;
        this.loading = true;
      } catch (err) {
        console.log(err);
      }
    },

    handleFileChange(event) {
      this.projectData.image = event.target.files[0];
      var input = event.target;
      if (input.files) {
        var reader = new FileReader();
        reader.onload = (e) => {
          this.preview = e.target.result;
        };
        this.image = input.files[0];
        reader.readAsDataURL(input.files[0]);
      }
    },

    async editProject() {
      try {
        this.projectData.managers = this.selectedManagers;
        let formData = convertToFormData(this.projectData, ["image"]);
        formData.delete("clientInfo");
        formData.delete("contractorInfo");
        if (typeof this.projectData.client == "object") {
          formData.delete("client");
        }
        if (typeof this.projectData.contractor == "object") {
          formData.delete("contractor");
        }
        const resp = await api.patch(
          `/api/project/${this.$route.params.id}/`,
          formData
        );
        this.showAddClient = false;
        this.showAddContractor = false;
        console.log(resp);
        this.$refs.editCustomModal.closeModal();
        this.$notify({
          type: "success",
          title: "Project updated",
          text: "Project updated succesfuly",
        });
        this.getProject(this.projectId);
      } catch (err) {
        console.log(err);
      }
    },

    openProjectForm(projectId) {
      this.isProjectFormOpen = true;
      this.editProjectId = projectId;
    },

    closeProjectForm() {
      this.getProject();
      this.isProjectFormOpen = false;
      this.editProjectId = null; // Reset the editTaskId after closing
    },

    openTaskForm(taskId = null, projectId = null) {
      this.isTaskFormOpen = true;
      this.editTaskId = taskId;
      this.currentProjectId = projectId;
    },

    closeTaskForm() {
      this.getProjectTasks();
      this.isTaskFormOpen = false;
      this.editTaskId = null; // Reset the editTaskId after closing
    },

    async updateTaskStatus(taskId, taskStatus) {
      try {
        const resp = api.patch(`/api/task/${taskId}/`, {
          status: taskStatus,
        });
        this.taskData.status = taskStatus;
        console.log(resp);
        this.$notify({
          type: "success",
          title: "Task updated",
          text: `Task set to ${taskStatus} succesfuly`,
        });
        this.getProject(this.projectId);
      } catch (err) {
        console.log(err);
      }
    },

    async deleteTask() {
      try {
        this.loading = true;
        const response = await api.delete(`/api/task/${this.deleteTaskId}/`);
        console.log(response);
        this.$refs.sweetAlert.closeModal();
        this.notify({
          type: "error",
          title: "Task Deleted",
          text: `Task deleted succesfuly`,
        });
        this.getProjectTasks();
        this.loading = false;
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },

    async updateProjectStatus(projectStatus) {
      try {
        this.projectData.status = projectStatus;
        const resp = api.patch(`/api/project/${this.projectId}/`, {
          status: projectStatus,
        });
        this.getProject();
        console.log(resp);
        this.$notify({
          type: "success",
          title: "Task updated",
          text: `Task set to ${
            projectStatus == "pending" ? "Pre Construction" : projectStatus
          } succesfuly`,
        });
      } catch (err) {
        console.log(err);
      } finally {
        this.getProject();
      }
    },

    handleFileSelect(event) {
      let csvFile = event.target.files[0];
      this.uploadTasksSheet(csvFile);
      this.selectedFileName = csvFile.name;
    },

    //  handleDrop(event) {
    //   event.preventDefault();

    //   const fileList = Array.from(event.dataTransfer.files).map(file => file.name);
    //   this.selectedFileName = fileList.join(', ');
    // }

    removeFile() {
      this.selectedFileName = "";
      document.getElementById("docpicker").value = "";
    },

    async uploadTasksSheet(tasksFile) {
      try {
        this.Loading = true;
        const resp = await api.post(
          `/api/task/bulk-upload/${this.$route.params.id}/`,
          {
            file: tasksFile,
          }
        );
        this.$notify({
          type: "success",
          title: "File Uploaded Successfully",
          text: `Tasks Will be generated automatically`,
        });
        console.log(resp);
        this.getProject();
        this.Loading = false;
      } catch (err) {
        console.log(err);
      }
    },
  },

  mounted() {
    this.projectId = this.$route.params.id;
    this.getProject(this.projectId);
    this.getWorkershandler();
    this.getProjectTasks();
    document
      .getElementById("docpicker")
      .addEventListener("change", this.handleDrop);
  },
};
</script>

<style scoped>
.fa-trash-alt:hover {
  color: #c93636;
}
.file-upload {
  border: 2px dashed #ccc;
  border-radius: 5px;
  padding: 20px;
  text-align: center;
  cursor: pointer;
}

.file-label {
  display: block;
}

.cus-inputField {
  display: none;
}

.selected-file {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.upload-instructions {
  margin-top: 10px;
}

.remove-file {
  cursor: pointer;
}

.button-container {
  margin-top: 20px;
  display: flex;
  justify-content: space-around;
}

.action-btn {
  background-color: #82d616;
  color: #fff;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.dropdown-card {
  position: absolute;
  left: 0; /* Adjust as needed */
  top: 0;
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 10px;
  border-radius: 8px;
  z-index: 1;
}

.dropdown-card button {
  display: block;
  width: 100%;
  padding: 8px;
  margin-bottom: 8px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.dropdown-card button:hover {
  background-color: #0056b3;
}
</style>
