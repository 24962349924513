<template>
  <div>
    <svg
      width="12px"
      height="12px"
      viewBox="0 0 42 42"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
    >
      <title>multi-users</title>
      <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g
          transform="translate(-1869.000000, -293.000000)"
          fill="#FFFFFF"
          fill-rule="nonzero"
        >
          <g
            class="color-background"
            transform="translate(1716.000000, 291.000000)"
          >
            <g id="multi-users" transform="translate(153.000000, 2.000000)">
              <!-- User 1 -->
              <circle cx="10" cy="28.5" r="8.5"></circle>
              <!-- User 2 -->
              <circle cx="22" cy="28.5" r="8.5"></circle>
              <!-- User 3 -->
              <circle cx="34" cy="28.5" r="8.5"></circle>
              <!-- Body -->
              <path
                class="color-background"
                d="M12.25,17.5 L8.75,17.5 L8.75,1.75 C8.75,0.78225 9.53225,0 10.5,0 L31.5,0 C32.46775,0 33.25,0.78225 33.25,1.75 L33.25,12.25 L29.75,12.25 L29.75,3.5 L12.25,3.5 L12.25,17.5 Z"
                opacity="0.6"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  name: "MultiUsersIcon",
};
</script>

<style scoped>
/* Add custom styles if needed */
</style>
