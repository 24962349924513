<template>
  <div>
    <Placeloader v-if="this.loading"> </Placeloader>
    <div v-else class="tasks-info">
      <div class="card mb-4">
        <div
          class="card-header p-3"
          style="
            display: flex;
            justify-content: space-between;
            align-items: center;
          "
          @click="this.closeDropdown()"
        >
          <h6>Tasks</h6>
          <soft-button-vue
            v-if="
              this.$store.state.user.role == 'admin' ||
              this.$store.state.user.role == 'manager'
            "
            @click="
              () => {
                this.openTaskForm(null);
              }
            "
            size="md"
            variant="gradient"
          >
            <slot>Add Task</slot></soft-button-vue
          >
        </div>

        <!-- tasks -->
        <div class="card-body px-0 pt-0 pb-2">
          <div class="table-responsive p-0">
            <table class="table mb-0">
              <thead @click="this.closeDropdown()">
                <tr>
                  <th
                    class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                  >
                    Title
                  </th>
                  <th
                    class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                  >
                    Quantity / Unit
                  </th>
                  <th
                    class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                  >
                    Workers
                  </th>
                  <th
                    class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                  >
                    Start Date
                  </th>
                  <th
                    class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                  >
                    End Date
                  </th>
                  <th
                    class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                  >
                    Status
                  </th>
                  <th
                    v-if="
                      this.$store.state.user.role == 'admin' ||
                      this.$store.state.user.role == 'manager'
                    "
                    class="text-secondary opacity-7"
                  >
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                <!-- v-for="item in managersData" :key="item.id" -->
                <tr v-for="task in projectTasks" :key="task.id">
                  <td @click="this.closeDropdown()">
                    <div class="d-flex px-2 py-1">
                      <div class="d-flex flex-column justify-content-center">
                        <h6 class="mb-0 text-sm">{{ task.title }}</h6>
                        <p class="text-xs text-secondary mb-0">
                          <span style="color: black"> Cost-code: </span>
                          {{
                            task.costCode
                              ? task.costCode.slice(0, 42)
                              : "Not mentioned"
                          }}
                        </p>
                        <p class="text-xs text-secondary mb-0">
                          <span style="color: black"> Description: </span>
                          {{
                            task.description
                              ? task.description.slice(0, 42)
                              : "Not mentioned"
                          }}
                        </p>
                      </div>
                    </div>
                  </td>
                  <td
                    @click="this.closeDropdown()"
                    class="align-middle text-start text-sm"
                  >
                    <span class="text-secondary ml-5 text-xs font-weight-bold"
                      >{{ task.quantity ? task.quantity : "N/A" }} /
                      {{ task.unit ? task.unit : "N/A" }}</span
                    >
                  </td>
                  <td @click="this.closeDropdown()">
                    <div class="avatar-group mt-2">
                      <a
                        v-for="manager in task.workers"
                        :key="manager.id"
                        href="#"
                        class="avatar avatar-xs rounded-circle zoomout"
                        data-bs-toggle="tooltip"
                        data-bs-placement="bottom"
                        :data-bs-original-title="
                          manager.username ? manager.username : 'Hi'
                        "
                      >
                        <img
                          :src="manager.avatar ? manager.avatar : spaceImg"
                          rounded-circle
                          alt="image"
                        />
                        <P style="position: absolute; bottom: 7px">{{
                          manager.username ? manager.username : "-- -- ----"
                        }}</P>
                      </a>
                    </div>
                  </td>
                  <td
                    @click="this.closeDropdown()"
                    class="align-middle text-center text-sm"
                  >
                    <span class="text-secondary text-xs font-weight-bold">{{
                      task.startDate ? task.startDate : "-- -- ----"
                    }}</span>
                  </td>
                  <td
                    @click="this.closeDropdown()"
                    class="align-middle text-center text-sm"
                  >
                    <span class="text-secondary text-xs font-weight-bold">{{
                      task.endDate ? task.endDate : "-- -- ----"
                    }}</span>
                  </td>
                  <td
                    @click="this.closeDropdown()"
                    class="align-middle text-center"
                  >
                    <soft-badge color="warning" variant="gradient" size="sm">{{
                      task.status
                    }}</soft-badge>
                  </td>
                  <td
                    v-if="
                      this.$store.state.user.role == 'admin' ||
                      this.$store.state.user.role == 'manager'
                    "
                    class="align-end"
                  >
                    <div class="cus-dropdown">
                      <button
                        class="cus-dropdown-toggle"
                        @click="toggleDropdown(task.id)"
                      >
                        <i class="fas fa-ellipsis-v"></i>
                      </button>
                      <div
                        class="cus-dropdown-menu"
                        v-if="currentTask == task.id"
                      >
                        <a
                          class="cus-link-button"
                          @click="updateTaskStatus(task.id, 'active')"
                          :style="{
                            color: task.status == 'active' ? '#82d616' : '',
                          }"
                        >
                          <p class="cus-title">Active</p>
                          <p class="cus-subtitle">Change Status to Active</p>
                        </a>
                        <a
                          class="cus-link-button"
                          @click="updateTaskStatus(task.id, 'pending')"
                          :style="{
                            color: task.status == 'pending' ? '#82d616' : '',
                          }"
                        >
                          <p class="cus-title">Pending</p>
                          <p class="cus-subtitle">Set task pending.</p>
                        </a>
                        <a
                          class="cus-link-button"
                          @click="updateTaskStatus(task.id, 'completed')"
                          :style="{
                            color: task.status == 'completed' ? '#82d616' : '',
                          }"
                        >
                          <p class="cus-title">Completed</p>
                          <p class="cus-subtitle">Mark task as completed</p>
                        </a>
                        <div class="div-line"></div>
                        <a
                          class="cus-link-button"
                          @click="this.openTaskForm(task.id)"
                        >
                          <p class="cus-title">Edit</p>
                          <p class="cus-subtitle">Edit Task information</p>
                        </a>
                        <a
                          class="cus-link-button"
                          @click="this.openAlert(task.id)"
                        >
                          <p class="cus-title">Delete</p>
                          <p class="cus-subtitle">Delete Task Permanently.</p>
                        </a>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <sweetAlert ref="sweetAlert" :alertData="alertData">
      <template v-slot:actions>
        <soft-button-vue
          color="danger"
          :loading="loading"
          @click="
            () => {
              this.$refs.sweetAlert.closeModal();
            }
          "
        >
          Cancel
        </soft-button-vue>
        <soft-button-vue @click="this.deleteTask()" :loading="loading">
          Delete Task
        </soft-button-vue>
      </template>
    </sweetAlert>

    <update-task-vue
      v-if="isTaskFormOpen"
      :projectID="this.projectid"
      :isOpen="isTaskFormOpen"
      :closeModal="closeTaskForm"
      :taskId="editTaskId"
    />
  </div>
</template>
<script>
import img1 from "@/assets/img/home-decor-1.jpg";
import SoftButtonVue from "../../components/SoftButton.vue";
import img2 from "@/assets//img/home-decor-1.jpg";
import { convertToFormData } from "@/supportElements/common.js";
import useApi from "../../supportElements/useAPI";
import spaceImg from "@/assets/img/team-4.jpg";
import SoftBadge from "../../components/SoftBadge.vue";
import updateTaskVue from "../SupportComponents/updateTask.vue";
import sweetAlert from "../components/customAlert.vue";

import Placeloader from "../SupportComponents/Placeloader.vue";

const api = useApi();
export default {
  name: "ProjectsDetail",
  props: {
    projectID: {
      type: Number,
      default: 0,
    },
  },
  emits: ["trigger-parent-function"],
  data() {
    return {
      showDropdown: false,
      currentTask: 0,
      isOpen: false,
      deleteTaskId: 0,
      activeTasks: 0,
      projectid: 0,
      taskData: {},
      selectedManagers: [],
      preview: null,
      isProjectFormOpen: false,
      isTaskFormOpen: false,
      editTaskId: "",
      currentProjectId: "",
      editProjectId: null,
      loading: false,
      assignToMore: false,
      Taskstatus: [
        { value: "active", name: "Active" },
        { value: "pending", name: "Pre Construction" },
        { value: "completed", name: "Completed" },
        { value: "cancelled", name: "cancelled" },
      ],
      editmodalTitle: "Edit Project",
      modalTitle: "Add Task",
      alertData: {
        icon: "fa fa-bell",
        alertTitle: "Delete Task ?",
        alertDescription:
          "After deleting this task you will not be able to recover it",
      },
      delteAlertData: {
        icon: "fa fa-warning",
        alertTitle: "Delete Project Permanently?",
        alertDescription:
          "After deleting this Project you will not be able to recover it",
      },
      workersData: [{ id: 0, username: "", email: "", phoneNumber: "" }],
      projectTasks: [
        {
          id: 0,
          title: "",
          description: "",
          startDate: "",
          endDate: "",
          project: 0,
          status: "",
          worker: [{ id: 0, username: "", email: "", role: "", avatar: "" }],
          quantity: "",
          unit: "",
          costCode: "",
        },
      ],
      img1,
      img2,
      spaceImg,
    };
  },

  components: {
    updateTaskVue,
    sweetAlert,
    SoftBadge,
    SoftButtonVue,
    Placeloader,
  },
  // watch: {
  //   projectID: "getProjectTasks",
  // },
  methods: {
    triggerParentFunction() {
      this.$emit("trigger-parent-function", this.projectID);
    },
    toggleDropdown(taskId) {
      if (this.currentTask === taskId) {
        this.currentTask = null;
      } else {
        this.currentTask = taskId;
      }
    },
    openDeleteAlert(id) {
      this.$refs.deleteSweetAlert.openModal();
      this.projectIdDeleteTobe = id;
    },
    getActiveTasks() {
      this.activeTasks = this.projectTasks.filter(
        (tasks) => tasks.status == "active"
      );
      this.activeTasks = this.activeTasks.length;
    },
    closeTheModals() {
      this.taskData.title = "";
      this.taskData.description = "";
      this.taskData.startDate = "";
      this.taskData.endDate = "";
      this.taskData.workers = [];
      this.taskData.status = "";
    },

    openAlert(id) {
      this.$refs.sweetAlert.openModal();
      this.deleteTaskId = id;
    },

    async addTaskHandler() {
      try {
        this.loading = true;
        let formData = convertToFormData(this.taskData, []);
        const resp = await api.post("/api/task/", formData);
        this.closeTheModals();
        this.$refs.customModal.closeModal();
        this.getProjectTasks();
        // this.triggerParentFunction();
        this.$notify({
          type: "success",
          title: "Task Added",
          text: "Task added succesfuly",
        });
        console.log("task data", resp);
      } catch (err) {
        this.$notify({
          type: "error",
          title: "Warning",
          text: "Something went wrong",
        });
        console.log(err);
      } finally {
        this.loading = false;
      }
    },

    async getProjectTasks() {
      try {
        this.loading = true;
        const resp = await api.get(`api/task/${this.projectid}/project/`);
        this.projectTasks = resp.data;
        this.projectTasks.map((task) => ({
          ...task,
          isOpen: false,
        }));
        console.log(resp.data);
        this.getActiveTasks();
        // this.triggerParentFunction();
        this.loading = false;
      } catch (err) {
        console.log(err);
      }
    },

    openTaskForm(taskId = null) {
      this.isTaskFormOpen = true;
      this.editTaskId = taskId;
      this.currentProjectId = this.$props.projectID;
    },

    closeTaskForm() {
      this.getProjectTasks();
      this.isTaskFormOpen = false;
      this.currentTask = null;
      this.editTaskId = null; // Reset the editTaskId after closing
    },
    closeDropdown() {
      this.currentTask = null;
    },
    async updateTaskStatus(taskId, taskStatus) {
      try {
        this.loading = true;
        const resp = api.patch(`/api/task/${taskId}/`, {
          status: taskStatus,
          schedule_mode: this.$store.state.isScheduleMode,
        });
        this.getProjectTasks();
        this.triggerParentFunction();
        console.log(resp);
        this.$notify({
          type: "success",
          title: "Task updated",
          text: `Task set to ${taskStatus} succesfuly`,
        });
        this.currentTask = null;
        this.loading = false;
      } catch (err) {
        console.log(err);
      }
    },

    async deleteTask() {
      try {
        this.loading = true;
        const response = await api.delete(`/api/task/${this.deleteTaskId}/`);
        console.log(response);
        this.$refs.sweetAlert.closeModal();
        this.$notify({
          type: "error",
          title: "Task Deleted",
          text: `Task deleted succesfuly`,
        });
        this.getProjectTasks();
        this.loading = false;
        this.currentTask = null;
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },

    async getWorkershandler() {
      try {
        this.loading = true;
        const response = await api.get("/api/users/by-role/worker/", {});
        this.workersData = response.data;
        console.log("data", this.workersData);
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },
  },

  mounted() {
    this.projectid = this.$props.projectID;
    this.getProjectTasks();
  },
};
</script>

<style scoped>
.cus-dropdown {
  position: relative;
  display: inline-block;
}

.cus-dropdown-toggle {
  background-color: transparent;
  border: none;
  cursor: pointer;
  margin-left: 28px;
  margin-top: 10px;
}

.cus-dropdown-menu {
  position: absolute;
  top: 80%;
  right: 14px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1;
}

.cus-dropdown-menu .cus-link-button {
  display: block;
  width: 100%;
  padding: 6px 10px;
  margin: 0;
  text-align: left;
  border: none;
  background-color: transparent;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 0;
}

.div-line {
  width: 100%;
  border-top: 1px solid #adadad;
}

.cus-title {
  font-size: 16px;
  margin: 0;
}
.cus-subtitle {
  font-size: 12px;
  margin: 0;
}

.cus-dropdown-menu .cus-link-button:hover {
  background-color: #f0f0f0;
}

.action-btn {
  background-color: #82d616;
  color: #fff;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.dropdown-card {
  position: absolute;
  left: 0; /* Adjust as needed */
  top: 0;
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 10px;
  border-radius: 8px;
  z-index: 1;
}

.dropdown-card button {
  display: block;
  width: 100%;
  padding: 8px;
  margin-bottom: 8px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.dropdown-card button:hover {
  background-color: #0056b3;
}
</style>
