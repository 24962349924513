<template>
  <div>
    <div class="mb-6 container-fluid">
      <div class="card mb-4 row" style="overflow: hidden">
        <div
          class="card-header pb-0 col-11"
          style="display: flex; justify-content: space-between"
        >
          <h6>Your Home</h6>
        </div>
        <div class="card-body px-0 pb-2">
          <div v-if="!loading" class="table mb-0">
            <div
              class="row mb-3"
              style="padding-left: 30px; border-bottom: 1px solid #56565630"
            >
              <div
                class="col-xl-6 col-6 col-md-6 col-sm-12 mt-md-0 text-uppercase text-secondary text-s font-weight-bold opacity-7"
              ></div>
              <div
                class="col-xl-2 col-12 col-md-6 col-sm-12 mt-md-0 text-uppercase text-secondary text-s font-weight-bold opacity-7 ps-2"
              ></div>
              <div
                class="col-xl-2 col-6 col-md-6 col-sm-6 mt-md-0 text-uppercase text-secondary text-s font-weight-bold opacity-7"
              ></div>
              <div
                class="col-xl-2 col-6 col-md-6 col-sm-6 mt-md-0 text-center text-uppercase text-secondary text-s font-weight-bold opacity-7"
              ></div>
            </div>
            <div v-for="item in filteredProjects" :key="item.id">
              <div
                class="row mb-1 align-items-center"
                style="padding-left: 30px; overflow: hidden"
              >
                <div class="col-xl-6 col-md-6 col-sm-12 col-12 mt-md-0">
                  <div style="cursor: pointer" class="d-flex px-2 py-1">
                    <div>
                      <soft-avatar
                        :img="item.image ? item.image : img9"
                        size="sm"
                        class="me-3"
                        alt="xd"
                        borderRadius="sm"
                      />
                    </div>
                    <div class="d-flex flex-column justify-content-center">
                      <h6 class="mb-0 text-sm">{{ item.title }}</h6>
                      <p class="mb-0 text-sm">
                        {{
                          item.description
                            ? item.description.slice(0, 40)
                            : "...."
                        }}
                      </p>
                      <p class="text-xs text-secondary mb-0">
                        <i class="fa fa-map-marker" aria-hidden="true"></i>
                        {{ item.address ? item.address : "No address added" }}
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  class="col-xl-2 col-md-6 col-sm-4 col-4 mt-md-0 align-middle align-items-center content-align-center"
                >
                  <div class="avatar-group mt-2 align-items-center">
                    Manager
                    <a
                      v-for="manager in item.managers"
                      :key="manager.id"
                      href="#"
                      class="avatar avatar-xs rounded-circle zoomout"
                      data-bs-toggle="tooltip"
                      data-bs-placement="bottom"
                      :title="manager.username"
                    >
                      <img
                        :src="manager.avatar ? manager.avatar : img4"
                        rounded-circle
                        alt="image"
                      />
                      <P style="position: absolute; bottom: 7px">{{
                        manager.username
                      }}</P>
                    </a>
                  </div>
                </div>
                <div
                  class="col-xl-2 col-md-6 col-sm-4 col-3 mt-md-0 text-start text-sm"
                >
                  <span class="text-xs font-weight-bold">
                    <soft-badge
                      :color="ProjectStatuscolor[item.status]"
                      variant="gradient"
                      size="sm"
                      >{{ this.getProjectStatus[item.status] }}</soft-badge
                    >
                    <!-- -- Total tasks {{ item.total_tasks }} -->
                  </span>
                </div>
                <div
                  class="col-xl-2 col-md-6 col-sm-4 col-4 mt-md-0 align-middle content-align-center"
                >
                  <div class="d-flex align-items-center justify-content-center">
                    <span class="text-xs font-weight-bold mx-2"
                      >{{
                        item.percentage && item.percentage.toFixed(2)
                      }}%</span
                    >
                    <div>
                      <soft-progress
                        color="info"
                        class="mx-auto"
                        variant="gradient"
                        :percentage="item.percentage"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div
                class="row"
                style="
                  padding-left: 30px;
                  overflow: hidden;
                  border-bottom: 1px solid #56565630;
                  border-top: 1px solid #56565630;
                "
              >
                <div class="col-xl-12 col-md-12 col-sm-12 col-12">
                  <h6 class="text-s">Tasks</h6>
                </div>
              </div>
              <div
                style="
                  padding-left: 30px;
                  overflow: hidden;
                  border-bottom: 1px solid #56565630;
                "
                class="row"
                v-for="task in item.project_tasks"
                :key="task.id"
              >
                <div class="row">
                  <div class="col-xl-8 col-md-8 col-sm-8 col-8">
                    <h6 class="text-xs">{{ task.title }}</h6>
                  </div>
                  <div class="col-xl-4 col-md-4 col-sm-4 col-4">
                    <span>
                      <soft-badge
                        :color="ProjectStatuscolor[task.status]"
                        variant="gradient"
                        size="sm"
                        >{{ this.getProjectStatus[task.status] }}</soft-badge
                      ></span
                    >
                  </div>
                </div>
                <div class="col-xl-6 col-md-12 col-sm-12 col-12">
                  <p class="text-xs" style="white-space: wrap">
                    {{ task.description }}
                  </p>
                </div>
                <div
                  class="col-xl-2 col-md-4 col-sm-6 col-6 align-middle align-items-center"
                >
                  <span>Start at: {{ task.startDate }}</span>
                </div>
                <div
                  class="col-xl-2 col-md-4 col-sm-6 col-6 align-middle align-items-center"
                >
                  <span>End at: {{ task.endDate }}</span>
                </div>
                <div
                  class="col-xl-2 col-md-4 col-sm-4 col-4 align-middle align-items-center"
                ></div>
              </div>
            </div>
          </div>
          <div
            v-else
            style="
              display: flex;
              align-items: center;
              justify-content: center;
              width: 100%;
            "
          >
            <img src="/loading.gif" alt="" />
          </div>
          <div
            v-if="!loading && filteredProjects.length == 0"
            style="display: flex; align-items: center"
          >
            <h3 class="mt-5 mb-5" style="margin: auto">No project found</h3>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useAPI } from "@/supportElements/useAPI.js";
import SoftAvatar from "@/components/SoftAvatar.vue";
import SoftBadge from "@/components/SoftBadge.vue";
import setTooltip from "@/assets/js/tooltip.js";
import SoftProgress from "@/components/SoftProgress.vue";
import useApi from "../../supportElements/useAPI";
const api = useApi();
export default {
  name: "projects-card",
  data() {
    return {
      isProjectFormOpen: false,
      ProjectStatuscolor: {
        pending: "secondary",
        active: "warning",
        completed: "success",
      },
      query: "",
      getProjectStatus: {
        pending: "Pending",
        active: "Active",
        completed: "Completed",
      },
      filteredProjects: [
        {
          id: 0,
          total_tasks: 0,
          percentage: 0,
          project_tasks: [
            {
              id: 0,
              title: "",
              description: "",
              startDate: "",
              endDate: "",
              status: "",
              is_active: false,
              updated: "",
              created: "",
              color: "",
              project: 0,
              workers: [],
            },
          ],
          title: "",
          description: "",
          image: "",
          startDate: "",
          endDate: "",
          status: "",
          is_active: false,
          address: "",
          created: "",
          client: 0,
          contractor: 0,
          managers: [],
        },
      ],
      activeFilter: "all",
      isProjectCompleted: false,
      managerCount: 1,
      projectIdDeleteTobe: 0,
      alertData: {
        icon: "fa fa-warning",
        alertTitle: "Alert",
        alertDescription:
          "After deleting this Project, you will not be able to recover it.",
      },

      useAPI,
      modalTitle: "Add New Project",
      inputFieldValue: "",
      loading: false,
      projects: [],
      project: {
        title: "",
        description: "",
        image: File | String,
        startDate: "",
        endDate: "",
        is_active: false,
        managers: [],
      },
      managers: [{ id: 0, avatar: "", email: "", username: "" }],
    };
  },
  components: {
    SoftAvatar,
    SoftProgress,
    SoftBadge,
  },

  methods: {
    filterProject(filterType) {
      console.log(this.query);
      if (this.query && filterType == null) {
        this.activeFilter = "all";
        this.filteredProjects = this.projects.filter((project) =>
          project.title.toLowerCase().includes(this.query.toLowerCase())
        );
        return;
      } else if (!this.query && filterType == null) {
        console.log("inside empty wuery");
        this.filteredProjects = this.projects;
        return;
      } else {
        this.query = "";
        console.log("else");
      }
      this.activeFilter = filterType;
      if (this.activeFilter != "all") {
        let data = this.projects.filter(
          (project) => project.status == this.activeFilter
        );
        this.filteredProjects = data;
      } else {
        this.filteredProjects = this.projects;
      }
    },
    closeProjectModalHandler() {
      this.project.title = "";
      this.project.description = "";
      this.project.image = null;
      this.project.startDate = "";
      this.project.endDate = "";
      this.project.managers = [];
    },

    openDeleteAlert(id) {
      this.$refs.sweetAlert.openModal();
      this.projectIdDeleteTobe = id;
    },

    openCustomModal() {
      this.closeProjectModalHandler();
      this.$refs.customModal.openModal();
    },
    openProjectForm() {
      this.isProjectFormOpen = true;
    },

    closeProjectForm() {
      this.getProjectHandler();
      this.isProjectFormOpen = false;
      this.editProjectId = null; // Reset the editTaskId after closing
    },

    saveAndClose() {
      console.log("Input Field Value:", this.inputFieldValue);
      this.$refs.customModal.closeModal();
    },

    handleFileChange(event) {
      this.project.image = event.target.files[0];
    },

    async getProjectHandler() {
      try {
        this.loading = true;
        const response = await api.get(
          `api/project/${this.$store.state.user.id}/client-projects/`,
          {}
        );
        this.projects = response.data;
        this.filteredProjects = response.data;
        console.log(this.projects);
        this.loading = false;
      } catch (err) {
        console.log(err);
      } finally {
        console.log("");
        this.loading = false;
      }
    },

    async getManagersHandler() {
      try {
        this.loading = true;
        const response = await api.get("/api/users/by-role/manager/", {});
        this.managers = response.data;
        console.log("mangers", this.managers);
        this.loading = false;
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
        console.log("");
      }
    },

    async deleteProject() {
      try {
        this.loading = true;
        const response = api.delete(
          `/api/project/${this.projectIdDeleteTobe}/`
        );
        this.$refs.sweetAlert.closeModal();
        this.getProjectHandler();
        console.log("deleted", response);
        this.$notify({
          type: "error",
          title: "Project",
          text: "Project deleted successfuly",
        });
        this.loading = false;
      } catch (err) {
        console.log(err);
        console.log("not deleted");
      } finally {
        this.getProjectHandler();
        this.loading = false;
      }
    },
  },
  mounted() {
    this.userToken = this.token;
    setTooltip();
    this.getProjectHandler();
    this.getManagersHandler();
  },
};
</script>
<style scoped>
.filter-tabs {
  height: 60px;
}
.filter-tabs > button {
  margin-right: 20px;
  border: 5px solid transparent;
}
.active-btn {
  border-bottom: 5px solid rgb(5, 34, 250) !important;
  border-top: 5px solid rgb(5, 34, 250) !important;
}
.custom-checkbox input:checked + .checkmark {
  background-color: #4caf50;
  border-color: #4caf50;
}
.action-btn {
  background-color: #82d616;
  color: #fff;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.inputField {
  width: 100%;
  padding: 4px 24px;
  border-radius: 8px;
  border: 1px solid #cccccc;
}
.inputField:focus {
  border: 2px solid #82d616; /* Change the border color when in focus */
  outline: none; /* Remove the default focus outline */
  box-shadow: 0 0 5px #82d61670;
}
.inputField:active {
  background-color: #f8f9fa;
}
.dropdownOptions {
  display: flex;
  gap: 16px;
  border-radius: 8px;
}

.zoomout:hover {
  z-index: 1;
}
.dropdownOptions img {
  width: 24px;
  height: 24px;
  border-radius: 30px;
}
</style>
