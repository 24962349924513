<template>
  <div class="container-fluid">
    <CompletedProjects></CompletedProjects>
  </div>
</template>

<script>
import CompletedProjects from "../components/models/completedProjects";
import { useAPI } from "@/supportElements/useAPI.js";
import { mapState } from "vuex";
export default {
  name: "projects-card",
  data() {
    return {
      useAPI,
      modalTitle: "Add New Project",
      inputFieldValue: "",
      loading: false,
      isProjectFormOpen: false,
      projects: [],
      project: {
        title: "",
        description: "",
        image: File | String,
        startDate: "",
        endDate: "",
        is_active: false,
        managers: [],
      },
      managers: [{ id: 0, name: "" }],
    };
  },
  components: {
    CompletedProjects,
  },
  computed: {
    ...mapState(["token"]),
  },
  methods: {},
  mounted() {},
};
</script>
<style scoped>
.custom-checkbox input:checked + .checkmark {
  background-color: #4caf50;
  border-color: #4caf50;
}
.action-btn {
  background-color: #82d616;
  color: #fff;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.inputField {
  width: 100%;
  padding: 4px 24px;
  border-radius: 8px;
  border: 1px solid #cccccc;
}
.inputField:focus {
  border: 2px solid #82d616;
  outline: none;
  box-shadow: 0 0 5px #82d61670;
}
.inputField:active {
  background-color: #f8f9fa;
}
.dropdownOptions {
  border-radius: 8px;
}
</style>
