<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div
        style="
          margin-top: 24px;
          color: #fff;
          background: linear-gradient(to right, #17ad37, #98ec2d);
        "
        class="mx-2 mt-0 mb-4 overflow-hidden card card-body blur shadow-blur"
      >
        <div class="row gx-4">
          <div class="col-auto">
            <div class="avatar avatar-xl position-relative">
              <img
                :src="workerData.avatar ? workerData.avatar : '/preview.jpeg'"
                alt="profile_image"
                class="shadow-sm w-100 border-radius-lg"
              />
            </div>
          </div>
          <div class="col-auto my-auto">
            <div class="h-100">
              <h5 style="color: #fff" class="mb-1">
                {{ workerData.username }}
              </h5>
              <p class="mb-0 text-sm font-weight-bold">
                {{ workerData.role }}
              </p>
            </div>
          </div>
          <div
            class="mx-auto mt-3 col-lg-4 col-md-6 my-sm-auto ms-sm-auto me-sm-0"
          ></div>
        </div>
      </div>

      <div class="card mb-6">
        <div
          class="card-header p-3"
          style="
            display: flex;
            justify-content: space-between;
            align-items: center;
          "
        >
          <h6>Your Tasks</h6>
          <p></p>
        </div>

        <div class="card-body px-0 pt-0 pb-2">
          <div v-if="!loading" class="table-responsive p-0">
            <table class="table mb-0 mobileWidth">
              <div
                class="row mb-3"
                style="
                  padding-left: 30px;
                  overflow: hidden;
                  border-bottom: 1px solid #56565630;
                "
              >
                <div
                  class="col-xl-2 col-2 col-md-2 col-sm-2 mt-md-0 text-secondary text-s font-weight-bolder opacity-7"
                >
                  Projects
                </div>
                <div
                  class="col-xl-4 col-4 col-md-4 col-sm-4 mt-md-0 text-secondary text-s font-weight-bold opacity-7"
                >
                  Task
                </div>
                <div
                  class="col-xl-1 col-1 col-md-1 col-sm-1 mt-md-0 text-secondary text-s font-weight-bold opacity-7"
                >
                  Cost Code
                </div>
                <div
                  class="col-xl-2 col-2 col-md-2 col-sm-2 mt-md-0 text-secondary text-s font-weight-bold opacity-7"
                >
                  Quantity / Unit
                </div>
                <div
                  class="col-xl-1 col-1 text-center col-md-1 col-sm-1 mt-md-0 ml-6 text-secondary text-s font-weight-bold opacity-7"
                >
                  Status
                </div>
                <div
                  class="col-xl-2 col-2 col-md-2 col-sm-2 mt-md-0 text-secondary text-s font-weight-bold opacity-7"
                >
                  Start date / End date
                </div>
                <div
                  class="col-xl-2 col-2 col-md-2 col-sm-2 mt-md-0 text-secondary text-s font-weight-bold opacity-7"
                ></div>
              </div>
              <div v-for="item in tasks" :key="item.id">
                <div
                  class="row mb-1"
                  style="padding-left: 30px; overflow: hidden"
                >
                  <div class="col-xl-2 col-md-2 col-sm-2 col-2 mt-md-0">
                    <div class="d-flex px-2 py-1">
                      <div class="d-flex flex-column justify-content-center">
                        <h6 class="mb-0 text-sm">{{ item.project.title }}</h6>
                        <p class="text-xs text-secondary mb-0">
                          <i class="fa fa-map-marker" aria-hidden="true"></i>
                          {{ item.project.address }}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div
                    class="col-xl-4 col-md-4 col-sm-4 col-4 mt-md-0 align-middle content-align-center"
                  >
                    <h6 class="text-xs font-weight-bold mb-0">
                      {{ item.title ? item.title : "No title added" }}
                    </h6>
                    <p class="text-xs font-weight-bold mb-0">
                      {{
                        item.description
                          ? item.description
                          : "No Description added"
                      }}
                    </p>
                  </div>

                  <div
                    class="col-xl-1 col-md-1 col-sm-1 col-1 mt-md-0 align-middle content-align-center text-center text-sm"
                  >
                    <p class="text-xs font-weight-bold mb-0">
                      {{ item.costCode ? item.costCode : "Not Added" }}
                    </p>
                  </div>

                  <div
                    class="col-xl-1 col-md-1 col-sm-1 col-1 mt-md-0 align-middle content-align-center text-center text-sm"
                  >
                    <p class="text-xs font-weight-bold mb-0">
                      {{ item.quantity ? item.quantity : "N/A" }} /
                      {{ item.unit ? item.unit : "N/A" }}
                    </p>
                  </div>

                  <div
                    class="col-xl-2 col-md-2 col-sm-2 col-2 mt-md-0 content-align-end text-end text-sm"
                  >
                    <soft-badge
                      class="mr-3"
                      :color="item.status == 'active' ? 'success' : 'warning'"
                      variant="gradient"
                      size="sm"
                      >{{ item.status }}</soft-badge
                    >
                  </div>

                  <div
                    class="col-xl-2 col-md-2 col-sm-2 col-2 mt-md-0 align-middle text-start content-align-center"
                  >
                    <span class="text-xs font-weight-bold mb-0">
                      {{ item.startDate.slice(0, 10) }} /
                      {{ item.endDate.slice(0, 10) }}
                    </span>
                  </div>
                  <div
                    class="col-xl-2 col-md-2 col-sm-2 col-2 mt-md-0 align-middle text-center content-align-center"
                  >
                    <span
                      class="text-secondary text-xs font-weight-bold"
                    ></span>
                  </div>
                </div>
              </div>
            </table>
            <div
              v-if="loading"
              style="
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
              "
            >
              <img src="/loading.gif" alt="" />
            </div>
          </div>
        </div>
      </div>

      <div
        class="col-12 col-xl-12 col-md-12 col-sm-12"
        style="
          min-width: 980px;
          background-color: white;
          padding: 12px 20px;
          border-radius: 12px;
          overflow: auto;
        "
        id="fullCalendarView"
      >
        <div class="mb-6" style="width: 100%">
          <form id="manger-form" @submit.prevent="changeFilterHandler">
            <div class="flex-between">
              <div>
                <p>Total Tasks: {{ filteredEvents.length }}</p>
              </div>

              <select
                class="inputField showmobile"
                style="width: fit-content"
                v-model="activeFilter"
              >
                <option selected>Selecte Filter</option>
                <option
                  class="dropdownOptions"
                  v-for="worker in dropdownFilters"
                  :key="worker.key"
                  :value="worker"
                >
                  {{ worker }}
                </option>
              </select>

              <div class="filter-tabs hidemobile">
                <SoftButtonVue
                  @click="
                    () => {
                      activeFilter = 'all';
                      changeFilterHandler();
                    }
                  "
                  color="info"
                  variant="gradient"
                  size="sm"
                  :class="activeFilter == 'all' && 'active-btn'"
                >
                  All
                </SoftButtonVue>
                <SoftButtonVue
                  @click="
                    () => {
                      activeFilter = 'active';
                      changeFilterHandler();
                    }
                  "
                  color="warning"
                  variant="gradient"
                  size="sm"
                  :class="activeFilter == 'active' && 'active-btn'"
                >
                  Active
                </SoftButtonVue>
                <SoftButtonVue
                  @click="
                    () => {
                      activeFilter = 'pending';
                      changeFilterHandler();
                    }
                  "
                  color="secondary"
                  variant="gradient"
                  size="sm"
                  :class="activeFilter == 'pending' && 'active-btn'"
                >
                  Pre Construction
                </SoftButtonVue>
                <SoftButtonVue
                  @click="
                    () => {
                      activeFilter = 'completed';
                      changeFilterHandler();
                    }
                  "
                  color="success"
                  variant="gradient"
                  size="sm"
                  :class="activeFilter == 'completed' && 'active'"
                >
                  Completed
                </SoftButtonVue>
              </div>
              <button class="view-button" @click="toggleFullScreen()">
                <i
                  :class="fullWidthView ? 'fa fa-compress' : 'fa fa-expand'"
                ></i>
              </button>
            </div>
          </form>

          <FullCalendar :options="calendarOptions">
            <template v-slot:eventContent="arg">
              <div
                style="
                  display: flex;
                  flex-wrap: wrap;
                  align-items: center;
                  justify-content: space-between;
                "
              >
                <p
                  style="
                    font-weight: 600;
                    margin-bottom: 0px;
                    padding-left: 10px;
                  "
                >
                  {{ arg.event.title }}
                </p>
                <div>
                  <p style="margin-top: 8px; padding-right: 10px">
                    {{ new Date(arg.event.start).toDateString() }} to
                    {{ new Date(arg.event.end).toDateString() }}
                  </p>
                </div>
              </div>
              <!-- <p>{{ arg.event.extendedProps.description }}</p> -->
            </template>
          </FullCalendar>
          <div
            v-if="filteredResources.length == 0"
            style="display: flex; align-items: center; justify-content: center"
          >
            <h4 class="mt-5 mb-5" style="color: darkgray">No project found</h4>
          </div>
        </div>
      </div>
    </div>
    <custom-modal ref="editProfileModal" :title="modalTitle">
      <!-- Custom content for the modal -->
      <form id="manger-form" @submit.prevent="editselfProfile">
        <div>
          <label for="inputField">Full name</label>
          <input
            class="inputField"
            type="text"
            placeholder="First name"
            v-model="workerData.username"
            required
          />
        </div>

        <div class="row">
          <div class="col-6">
            <label for="inputField">Email</label>
            <input
              autocomplete="username"
              class="inputField"
              v-model="workerData.email"
              type="email"
              required
              placeholder="Email"
            />
          </div>
          <div class="col-6">
            <label for="inputField">Phone No</label>
            <input
              class="inputField"
              v-model="workerData.phoneNumber"
              type="tel"
              required
              placeholder="Phone number"
            />
          </div>
        </div>
        <div>
          <label for="inputField">Password</label>
          <input
            required
            class="inputField"
            type="password"
            placeholder="Password"
            v-model="workerData.password"
          />
        </div>
        <div class="row">
          <div class="col-6">
            <label for="inputField">Image</label>
            <input
              class="inputField"
              type="file"
              accept="image/*"
              @change="handleFileChange"
              size="md"
            />
          </div>
          <div class="col-6">
            <img
              style="width: 200px; border-radius: 100px; margin-top: 20px"
              :src="editpreview ? editpreview : '/preview.jpeg'"
              alt="asdas"
            />
          </div>
        </div>
      </form>
      <template v-slot:actions>
        <soft-button-vue
          @click="editselfProfile()"
          :loading="loading"
          form="manger-form"
          type="submit"
        >
          Save changes
        </soft-button-vue>
      </template>
    </custom-modal>
  </div>
</template>
<script>
import FullCalendar from "@fullcalendar/vue3";
import resourceTimelinePlugin from "@fullcalendar/resource-timeline";
import useApi from "../supportElements/useAPI";
import SoftBadge from "../components/SoftBadge.vue";
import CustomModal from "./components/CustomModal.vue";
import { convertToFormData } from "../supportElements/common";
import SoftButtonVue from "../components/SoftButton.vue";
const api = useApi();
export default {
  components: {
    FullCalendar,
    SoftBadge,
    SoftButtonVue,
    CustomModal,
  },
  data() {
    return {
      modalTitle: "Edit Profile",
      editpreview: null,
      workerData: {},
      fullWidthView: false,
      dropdownFilters: {
        all: "all",
        active: "active",
        pending: "pending",
        completed: "completed",
      },
      activeFilter: "all",
      colors: {
        pending: "#fbcf33",
        active: "#82d616",
        completed: "#cb0c9f",
        canceled: "#344767",
      },
      query: "",
      tasks: [],
      filteredResources: [],
      filteredEvents: [],
      projects: [],
      events: [],
      calendarOptions: {
        plugins: [resourceTimelinePlugin],
        schedulerLicenseKey: "0965592368-fcs-1694657447",
        initialView: "resourceTimelineMonth",
        height: "auto",
        resourceAreaWidth: "20%",
        selectable: true,
        headerToolbar: {
          left: "today prev,next",
          center: "title",
          right:
            "resourceTimelineWeek,resourceTimelineMonth,resourceTimelineYear",
        },
        editable: true,
        views: {
          resourceTimelineWeek: {
            slotDuration: { days: 1, hours: 1 }, // Each slot represents 1 hour
            slotLabelFormat: {
              weekday: "short",
              month: "numeric",
              day: "numeric",
              year: "numeric",
            }, // Custom slot label format
          },
        },
        resourceAreaHeaderContent: "Projects",
        resources: this.filteredResources,
      },
    };
  },
  watch: {
    activeFilter: "changeFilterHandler",
  },
  methods: {
    renderCalender() {
      console.log("calende render");
      console.log(this.tasks);
      this.events = this.tasks.map((task) => ({
        id: task.id,
        resourceId: task.project.id,
        start: task.startDate,
        end: task.endDate,
        title: task.title,
        color: task.color,
        description: task.description,
        workers: task.workers,
        borderColor: this.colors[task.status],
        status: task.status,
      }));
      this.projects = this.tasks.map((task) => ({
        id: task.project.id,
        start: task.project.startDate,
        end: task.project.endDate,
        title: task.project.title,
        address: task.project.address,
        status: task.project.status,
        color: this.colors[task.project.status],
      }));
      this.filteredResources = this.projects;
      this.filteredEvents = this.events;
      this.calendarOptions.resources = this.projects;
      this.calendarOptions.events = this.events;
    },
    changeFilterHandler() {
      console.log("func caleed", this.activeFilter);

      if (this.activeFilter != "all") {
        console.log(this.events);
        let data = this.events.filter(
          (event) => event.status == this.activeFilter
        );
        console.log("local data length", data.length);
        this.filteredEvents = data;
      } else {
        this.filteredEvents = this.events;
      }

      this.calendarOptions.events = this.filteredEvents;

      // this.setResources([])
    },

    async gettasksHandler() {
      try {
        const response = await api.get(
          `/api/task/${this.$store.state.user.id}/worker-tasks/`,
          {}
        );
        this.tasks = response.data;
        console.log("worker tasks", this.tasks);
      } catch (err) {
        this.tasks = [];
      }
    },

    async getWorkerHandler() {
      try {
        const response = await api.get(
          `/api/users/${this.$store.state.user.id}`
        );
        this.workerData = response.data;
      } catch (err) {
        console.log(err);
      }
    },
    showFullView() {
      this.fullWidthView = !this.fullWidthView;
    },
    async editselfProfile() {
      try {
        this.loading = true;
        let formData = convertToFormData(this.workerData, ["avatar"]);
        const resp = await api.patch(
          `/api/users/${this.workerData.id}/`,
          formData
        );
        this.$store.commit("setUser", resp.data);
        this.workerData = resp.data;
        this.$notify({
          type: "success",
          title: "Profile updated",
          text: "Entered information of your profile has beed updated",
        });
        // this.userData = this.$store.state.user;
        this.$refs.editProfileModal.closeModal();
      } catch (err) {
        console.log(err);
        this.$notify({
          type: "error",
          title: "Something went wrong !",
          text: "Something wrong has happened please try again.",
        });
      } finally {
        this.loading = false;
      }
    },
    handleFileChange(event) {
      this.userData.avatar = event.target.files[0];
      this.adminFormData.avatar = event.target.files[0];
      var input = event.target;
      if (input.files) {
        var reader = new FileReader();
        reader.onload = (e) => {
          this.preview = e.target.result;
          this.editpreview = e.target.result;
        };
        this.image = input.files[0];
        reader.readAsDataURL(input.files[0]);
      }
    },
    toggleFullScreen() {
      this.fullWidthView = !this.fullWidthView;
      const fullScreenCalender = document.getElementById("fullCalendarView");
      if (
        document.fullscreenElement ||
        document.webkitFullscreenElement ||
        document.mozFullScreenElement ||
        document.msFullscreenElement
      ) {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.webkitExitFullscreen) {
          document.webkitExitFullscreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        }
      } else {
        if (fullScreenCalender.requestFullscreen) {
          fullScreenCalender.requestFullscreen();
        } else if (fullScreenCalender.webkitRequestFullscreen) {
          fullScreenCalender.webkitRequestFullscreen();
        } else if (fullScreenCalender.mozRequestFullScreen) {
          fullScreenCalender.mozRequestFullScreen();
        } else if (fullScreenCalender.msRequestFullscreen) {
          fullScreenCalender.msRequestFullscreen();
        }
      }
    },
    toggleLandscapeFullScreen() {
      this.fullWidthView = !this.fullWidthView;
      const fullScreenCalendar = document.getElementById("fullCalendarView");
      if (
        document.fullscreenElement ||
        document.webkitFullscreenElement ||
        document.mozFullScreenElement ||
        document.msFullscreenElement
      ) {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.webkitExitFullscreen) {
          document.webkitExitFullscreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        }
      } else {
        if (fullScreenCalendar.requestFullscreen) {
          fullScreenCalendar.requestFullscreen();
        } else if (fullScreenCalendar.webkitRequestFullscreen) {
          fullScreenCalendar.webkitRequestFullscreen();
        } else if (fullScreenCalendar.mozRequestFullScreen) {
          fullScreenCalendar.mozRequestFullScreen();
        } else if (fullScreenCalendar.msRequestFullscreen) {
          fullScreenCalendar.msRequestFullscreen();
        }

        // Additional code to force landscape mode on mobile devices
        if (window.screen.orientation) {
          // If the browser supports screen orientation API
          window.screen.orientation.lock("landscape");
        } else if (screen.orientation && screen.orientation.lock) {
          // Fallback for older browsers that support screen.orientation
          screen.orientation.lock("landscape");
        } else if (screen.lockOrientation) {
          // Fallback for browsers that support screen.lockOrientation
          screen.lockOrientation("landscape");
        }
      }
    },
  },

  async mounted() {
    await this.gettasksHandler();
    this.renderCalender();
    this.getWorkerHandler();
  },
};
</script>
<style scoped lang="scss">
.filter-tabs {
  height: 60px;
}
.filter-tabs > button {
  margin-right: 12px;
  border: 5px solid transparent;
}
</style>
